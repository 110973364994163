import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EstateSmartCustomerResponse } from '../../../../models/estate-smart-customer-response';
import { MigrationConfigRequest } from '../../../../models/migration-config-request';
import { MigrationCustomerEditItem } from '../../../../models/migration-customers-response';
import { AdminService } from '../../../../services/admin.service';
import { SessionStorageService } from '../../../../services/session-storage-service';
import { SmartImporterService } from '../../../../services/smart-importer.service';
import { MigrationCustomerView } from './../../../../models/common-customer-view';

@Component({
  selector: 'iwt-estatecloud-migration-customer-header',
  templateUrl: './migration-customer-header.component.html',
  styleUrls: ['./migration-customer-header.component.scss'],
})
export class MigrationCustomerHeaderComponent implements OnInit {
  @Input() dbName: string | undefined;
  @Output() unselectedDbNameEvent = new EventEmitter<string | undefined>();
  messageShow: boolean = false;
  messageType: string = 'success';
  message: string = 'Migration wurde gestartet!';
  started: boolean = false;
  MigrationCustomerView = MigrationCustomerView;
  currentView = MigrationCustomerView.VIEW;
  showEditButton = true;
  customerDB: EstateSmartCustomerResponse | undefined;
  customerEditItem: MigrationCustomerEditItem = new MigrationCustomerEditItem();

  constructor(
    private adminService: AdminService,
    private sessionService: SessionStorageService,
    private smartImporterService: SmartImporterService,
  ) {}

  ngOnInit(): void {
    this.customerDB =
      this.sessionService.getMigrationEstateSmartCustomerByDbName(this.dbName);
    if (this.customerDB) {
      this.customerEditItem.iwVerwKdNr = this.customerDB.iwVerwKdNr;
      this.customerEditItem.isDomestic = false;
    }
  }

  async startMigrationClicked() {
    if (!this.dbName) return;

    this.messageShow = false;
    this.started = true;

    const customer = this.customerDB;
    if (!customer) {
      this.showMessage(
        'error',
        'Es wurden keine Daten im SessionStorage gefunden!',
      );
      this.started = false;
      return;
    }

    if (!Number(customer.iwVerwKdNr)) {
      this.showMessage('error', 'Die Kundennummer ist ungültig!');
      this.started = false;
      return;
    }

    customer.company = this.truncateCompanyName(customer.company);

    const tenantUuid = await this.startCreateCustomer(customer);
    if (!tenantUuid) {
      this.started = false;
      return;
    }

    if (!(await this.createConfig(customer, tenantUuid))) {
      this.started = false;
      return;
    }

    await this.startMigration(tenantUuid);
    this.started = false;
  }

  async createConfig(
    customer: EstateSmartCustomerResponse,
    tenantUuid: string,
  ) {
    try {
      await this.smartImporterService.createConfig(
        this.getConfigDataToStore(customer, tenantUuid),
      );
      this.showMessage(
        'success',
        'Die Config der Migration wurde erfolgreich geschrieben!',
      );
    } catch (error) {
      this.showMessage(
        'error',
        'Die Config konnte nicht geschrieben werden! Versuchen Sie es später erneut.',
      );
      return false;
    }
    return true;
  }

  async startMigration(tenantUuid: string) {
    if (!tenantUuid) return;
    try {
      const result = await this.smartImporterService.startMigration(
        tenantUuid,
        'EstateSmart',
      );
      if (result)
        this.showMessage(
          'success',
          `Die Migration wurde erfolgreich gestartet (Tenant: ${tenantUuid}).`,
        );
      else
        this.showMessage(
          'error',
          `Die Migration wurde gestartet, es kam aber zu einem Fehler (Tenant: ${tenantUuid}).`,
        );
    } catch (error) {
      this.showMessage(
        'error',
        `Die Migration konnte nicht gestartet werden! versuchen Sie es später erneut (Tenant: ${tenantUuid}).`,
      );
    }
  }

  async startCreateCustomer(
    user: EstateSmartCustomerResponse,
  ): Promise<string> {
    if (!user.iwVerwKdNr) return '';
    if (!user.company) return '';

    try {
      const result = await this.adminService.createCustomerByUser(
        user,
        this.customerEditItem.isDomestic ?? false,
      );
      this.showMessage(
        'success',
        'Der Kunde wurde erfolgreich angelegt! Neuer Tenant: ' +
          result.tenantUuid,
      );
      return result.tenantUuid;
    } catch (e: unknown) {
      const error = e as Error;
      this.showMessage('error', error.message);
      return '';
    }
  }

  showMessage = (type: string, message: string) => {
    switch (type) {
      case 'success':
        this.messageType = 'success';
        break;

      case 'error':
        this.messageType = 'error';
        break;
    }
    this.messageShow = true;
    this.message = message;
  };

  private truncateCompanyName(companyName: string | undefined) {
    if (!companyName) return '';
    companyName = companyName.replace(/(\r\n|\n|\r)/gm, ' ');

    if (companyName.length > 50) {
      return companyName.substring(0, 50);
    }
    return companyName;
  }

  getConfigDataToStore(
    customer: EstateSmartCustomerResponse,
    tenantUuid: string,
  ) {
    const dataToBeStored: MigrationConfigRequest = {
      migrationType: 'EstateSmart',
      source: {
        name: 'estateSmart',
        apiKey: btoa(`${customer.userName}:${customer.password}`),
      },
      target: {
        tenantUuid: tenantUuid,
        editionConfig: 'XS',
      },
    };
    return dataToBeStored;
  }

  changedMigrationCustomer($event: MigrationCustomerEditItem) {
    if (this.customerDB) {
      this.customerDB.iwVerwKdNr = $event.iwVerwKdNr;
      this.customerEditItem.iwVerwKdNr = $event.iwVerwKdNr;
      this.customerEditItem.isDomestic = $event.isDomestic;
    }
  }

  back() {
    this.unselectedDbNameEvent.emit();
  }
}
