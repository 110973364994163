import { EstateSmartCustomerResponse } from './estate-smart-customer-response';

export class MigrationCustomersResponse {
  data!: EstateSmartCustomerResponse[];
  totalCount!: number;
}

export class MigrationConfigResponse {
  data!: []; // TODO - add type
  totalCount!: number;
}

export interface MigrationCustomerDictionary<MigrationCustomersResponse> {
  [Key: string]: MigrationCustomersResponse[];
}

export class MigrationCustomerItem {
  Key?: string;
  Users?: EstateSmartCustomerResponse[];
}

export class MigrationCustomerEditItem {
  iwVerwKdNr?: number = 0;
  isDomestic?: boolean = false;
}
