<div
  class="relative bg-white dark:bg-slate-800 rounded-2xl border mb-8 overflow-visible"
>
  <div class="p-4">
    <form [formGroup]="editMigrationCustomerForm">
      <div class="grid grid-cols-2 gap-6 md:grid-cols-3 mb-4">
        <iwt-estatecloud-input
          [form]="editMigrationCustomerForm"
          pattern="[1-9][0-9]*"
          controlName="customerId"
          label="Kundennummer*"
        ></iwt-estatecloud-input>

        <iwt-estatecloud-input
          [form]="editMigrationCustomerForm"
          controlName="isDomestic"
          label="Testaccount"
          inputType="checkbox"
        ></iwt-estatecloud-input>
      </div>
    </form>
  </div>
</div>
