import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MigrationCustomerEditItem } from '../../../../models/migration-customers-response';

@Component({
  selector: 'iwt-estatecloud-migration-edit-customer',
  templateUrl: './migration-edit-customer.component.html',
  styleUrls: [],
})
export class MigrationEditCustomerComponent implements OnInit {
  _customer: MigrationCustomerEditItem | undefined;
  _customerInit: MigrationCustomerEditItem | undefined;
  @Input() set customer(value: MigrationCustomerEditItem | undefined) {
    this.setCustomerData(value);
  }
  @Output() changeMigrationCustomerEvent =
    new EventEmitter<MigrationCustomerEditItem>();

  editMigrationCustomerForm = new FormGroup({
    customerId: new FormControl<string | undefined>(undefined, [
      Validators.required,
      Validators.pattern('^[1-9][0-9]*$'),
      Validators.maxLength(50),
    ]),
    isDomestic: new FormControl(false),
  });

  ngOnInit(): void {
    this.editMigrationCustomerForm.valueChanges.subscribe(() => {
      this.adopt();
    });
    this._customerInit = { ...this._customer };
  }

  adopt() {
    const customerData = this.extractCustomerData();

    if (this._customer) {
      this._customer.iwVerwKdNr = customerData.iwVerwKdNr;
      this._customer.isDomestic = customerData.isDomestic;
    }

    if (JSON.stringify(this._customerInit) === JSON.stringify(this._customer))
      return;

    this.changeMigrationCustomerEvent.emit(customerData);
  }

  setCustomerData(value: MigrationCustomerEditItem | undefined) {
    this._customer = value;

    const editMigrationCustomerFormControls =
      this.editMigrationCustomerForm.controls;
    editMigrationCustomerFormControls.customerId.setValue(
      this._customer?.iwVerwKdNr?.toString(),
    );
    editMigrationCustomerFormControls.isDomestic.setValue(
      this._customer?.isDomestic ?? false,
    );
  }

  extractCustomerData(): MigrationCustomerEditItem {
    const editMigrationCustomerFormControls =
      this.editMigrationCustomerForm.controls;
    return {
      iwVerwKdNr: Number(
        editMigrationCustomerFormControls.customerId.value ?? -1,
      ),
      isDomestic: editMigrationCustomerFormControls.isDomestic.value ?? false,
    };
  }
}
