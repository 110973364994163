import { Injectable } from '@angular/core';
import { CustomerRequest } from '../models/customer-request';
import { CustomerResponse } from '../models/customer-response';
import { CustomersResponse } from '../models/customers-response';
import { EstateSmartCustomerResponse } from '../models/estate-smart-customer-response';
import { Filters } from '../models/filters';
import { Sort } from '../models/sort';
import { UsersResponse } from '../models/users-response';
import { AuthService } from './auth.service';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  constructor(
    private envService: EnvironmentService,
    private authService: AuthService,
  ) {}

  private async customersRequest(
    method: 'GET' | 'POST' | 'PATCH',
    urlAddition: string,
    body?: string,
  ): Promise<CustomerResponse> {
    const config = this.envService.getConfig();
    const init: RequestInit = {
      method,
      body,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + (await this.authService.getToken()),
      },
    };
    const res = await fetch(config.baseUrl + '/customers' + urlAddition, init);
    if (!res.ok) {
      const responseJson = await res.json();
      const message = responseJson.message;
      let errorMessage = '';

      if (typeof message === 'string') {
        errorMessage = JSON.parse(responseJson.message)?.endUserMessage;
      }

      if (message instanceof Array) {
        errorMessage = message.join(', ');
      }

      throw new Error(responseJson.statusCode + ' - ' + errorMessage);
    }
    return await res.json();
  }

  private isJSON(msg: string): boolean {
    try {
      JSON.parse(msg);
      return true;
    } catch (e) {
      return false;
    }
  }

  public async getCustomer(customerId: string): Promise<CustomerResponse> {
    return await this.customersRequest('GET', '/' + customerId);
  }

  public async getUsers(tenantUuid: string): Promise<UsersResponse> {
    const config = this.envService.getConfig();

    const init: RequestInit = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + (await this.authService.getToken()),
      },
    };
    const res = await fetch(
      config.baseUrl + '/accounts/' + tenantUuid + '/users',
      init,
    );
    if (!res.ok) {
      const responseJson = await res.json();
      if (responseJson?.statusCode == 404) return new UsersResponse();

      let errorMessage = responseJson.message;
      if (this.isJSON(responseJson.message)) {
        errorMessage = JSON.parse(responseJson.message)?.endUserMessage ?? '';
      }
      throw new Error(responseJson.statusCode + ' - ' + errorMessage);
    }
    return await res.json();
  }

  public async createCustomer(
    customer: CustomerRequest,
  ): Promise<CustomerResponse> {
    return await this.customersRequest('POST', '', JSON.stringify(customer));
  }

  public async updateCustomer({
    customerId,
    ...customer
  }: CustomerRequest): Promise<CustomerResponse> {
    return await this.customersRequest(
      'PATCH',
      '/' + customerId,
      JSON.stringify(customer),
    );
  }

  public async paginateCustomers(
    filter: Filters,
    sort?: Sort,
    offset: number = 0,
    limit: number = 50,
  ): Promise<CustomersResponse> {
    const config = this.envService.getConfig();
    const sortParam =
      sort === undefined ? '' : `&sort=${sort.sort}${sort.sortProperty}`;

    let query = `${config.baseUrl}/customers?offset=${offset}&limit=${limit}${sortParam}`;

    Object.entries(filter)
      .filter(
        ([, value]) => value !== undefined && value !== null && value !== '',
      )
      .forEach(([key, value]) => {
        query += `&${key}=${encodeURIComponent(value)}`;
      });

    const token = await this.authService.getToken();
    const res = await fetch(query, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    });
    if (!res.ok) {
      throw new Error(res.status + ' ' + res.statusText);
    }
    return await res.json();
  }

  public async deleteAccount(tenantUuid: string): Promise<void> {
    const config = this.envService.getConfig();
    const token = await this.authService.getToken();

    const init: RequestInit = {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
    const res = await fetch(`${config.baseUrl}/accounts/${tenantUuid}`, init);
    if (!res.ok) {
      const responseJson = await res.json();
      throw new Error(`${responseJson.statusCode} - ${responseJson.message}`);
    }
  }

  async createCustomerByUser(
    user: EstateSmartCustomerResponse,
    isDomestic: boolean = false,
  ): Promise<CustomerResponse> {
    const customer = this.getCustomerByUser(user, isDomestic);
    return await this.createCustomer(customer);
  }

  getCustomerByUser(
    user: EstateSmartCustomerResponse,
    isDomestic: boolean = false,
  ): CustomerRequest {
    const customer: CustomerRequest = {
      businessPhone: user.phone,
      city: user.city,
      companyName: user.company ?? '',
      contractId: user.iwVerwKdNr ?? 0,
      country: 'Deutschland',
      customerId: user.iwVerwKdNr?.toString() ?? '',
      edition: 'Standalone',
      editionConfig: 'XS',
      email: user.email,
      firstName: user.firstName,
      isDomestic: isDomestic,
      lastName: user.lastName,
      licenseCount: 1,
      migrationSource: 'EstateSmart',
      mobilePhone: user.mobile,
      salutation: user.salutation,
      street: user.street,
      zipCode: user.zip,
    };
    return customer;
  }

  async resendEmail(tenantUuid: string, useruuid: string): Promise<void> {
    const config = this.envService.getConfig();
    const init: RequestInit = {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + (await this.authService.getToken()),
      },
    };
    const res = await fetch(
      `${config.baseUrl}/accounts/${tenantUuid}/users/${useruuid}/resendemail`,
      init,
    );
    if (!res.ok) {
      throw new Error(res.status + ' ' + res.statusText);
    }
  }
}
